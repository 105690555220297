import React from "react";

import one from "../assets/icons/One.svg";
import two from "../assets/icons/Two.svg";
import three from "../assets/icons/Three.svg";
import four from "../assets/icons/Four.svg";
import five from "../assets/icons/Five.svg";
import six from "../assets/icons/Six.svg";

export default class HomeTechShowCase extends React.Component {
	render() {
		return (
			<div id="features" className="w-full min-h-screen flex flex-col justify-center items-center bg-blue-900 py-12">
				<div className="self-center text-center w-full sm:w-2/3 xl:w-1/2 px-4 sm:px:0">
					<h2 className="tracking-wide text-blue-200 font-bold text-4xl mb-4">
						TECH S.H.O.W.C.A.S.E <br />
						<i>Unveiling the Future of Development</i>
					</h2>
					<div className="font-light text-gray-200 text-xl mb-6">
						<p>Embark on a journey through our Technology Showcase, where we unveil the cutting-edge tools and frameworks that power the future of software development. DevStackUp prides itself on staying at the forefront of technological innovation, and our showcase is a testament to the versatility and proficiency of our development team.</p>
					</div>
				</div>
				<div className="self-center w-full xl:w-4/5 flex flex-col sm:flex-row flex-wrap px-4 xl:px:0">
					<div className="w-full sm:w-1/2 flex flex-row flex-no-wrap hover:shadow-lg hover:bg-blue-800 hover:bg-opacity-50 px-4 sm:px-8 py-6 sm:py-12">
						<div className="w-1/3 xl:w-1/4">
							<img src={one} alt="One" className="w-16 block sm:mx-auto" />
						</div>
						<div className="w-2/3 xl:w-3/4">
							<h3 className="tracking-wide text-blue-300 font-bold text-2xl uppercase mb-2">Versatile Coding Languages</h3>
							<div className="font-light text-gray-200 text-lg">
								<p>Explore a spectrum of coding languages, from foundational languages like Python and JavaScript to specialized languages catering to diverse project requirements. Our proficiency spans the breadth of languages, ensuring that we leverage the most suitable tools for each project.</p>
							</div>
						</div>
					</div>
					<div className="w-full sm:w-1/2 flex flex-row flex-no-wrap hover:shadow-lg hover:bg-blue-800 hover:bg-opacity-50 px-4 sm:px-8 py-6 sm:py-12">
						<div className="w-1/3 xl:w-1/4">
							<img src={two} alt="Two" className="w-16 block sm:mx-auto" />
						</div>
						<div className="w-2/3 xl:w-3/4">
							<h3 className="tracking-wide text-blue-300 font-bold text-2xl uppercase mb-2">Framework Mastery</h3>
							<div className="font-light text-gray-200 text-lg">
								<p>Delve into the intricacies of powerful frameworks that streamline development processes and enhance scalability. Whether it's front-end frameworks like React and Angular or back-end frameworks like Django and Express, we showcase our mastery in navigating the dynamic landscape of development frameworks.</p>
							</div>
						</div>
					</div>
					<div className="w-full sm:w-1/2 flex flex-row flex-no-wrap hover:shadow-lg hover:bg-blue-800 hover:bg-opacity-50 px-4 sm:px-8 py-6 sm:py-12">
						<div className="w-1/3 xl:w-1/4">
							<img src={three} alt="Three" className="w-16 block sm:mx-auto" />
						</div>
						<div className="w-2/3 xl:w-3/4">
							<h3 className="tracking-wide text-blue-300 font-bold text-2xl uppercase mb-2">Cloud Technologies</h3>
							<div className="font-light text-gray-200 text-lg">
								<p>Witness the seamless integration of cloud technologies into our development ecosystem. From AWS and Azure to Google Cloud, we harness the power of cloud computing to optimize infrastructure, enhance security, and facilitate scalable solutions.</p>
							</div>
						</div>
					</div>
					<div className="w-full sm:w-1/2 flex flex-row flex-no-wrap hover:shadow-lg hover:bg-blue-800 hover:bg-opacity-50 px-4 sm:px-8 py-6 sm:py-12">
						<div className="w-1/3 xl:w-1/4">
							<img src={four} alt="Four" className="w-16 block sm:mx-auto" />
						</div>
						<div className="w-2/3 xl:w-3/4">
							<h3 className="tracking-wide text-blue-300 font-bold text-2xl uppercase mb-2">DevOps Excellence</h3>
							<div className="font-light text-gray-200 text-lg">
								<p>Experience the synergy of development and operations through our DevOps showcase. Explore how we implement continuous integration, deployment, and delivery pipelines, ensuring efficiency, reliability, and rapid iteration in our development workflows.</p>
							</div>
						</div>
					</div>
					<div className="w-full sm:w-1/2 flex flex-row flex-no-wrap hover:shadow-lg hover:bg-blue-800 hover:bg-opacity-50 px-4 sm:px-8 py-6 sm:py-12">
						<div className="w-1/3 xl:w-1/4">
							<img src={five} alt="Five" className="w-16 block sm:mx-auto" />
						</div>
						<div className="w-2/3 xl:w-3/4">
							<h3 className="tracking-wide text-blue-300 font-bold text-2xl uppercase mb-2">Emerging Technologies</h3>
							<div className="font-light text-gray-200 text-lg">
								<p>Step into the realm of emerging technologies such as AI, machine learning, and blockchain. Our showcase highlights how we integrate these transformative technologies to bring innovation and efficiency to a variety of projects, pushing the boundaries of what's possible.</p>
							</div>
						</div>
					</div>
					<div className="w-full sm:w-1/2 flex flex-row flex-no-wrap hover:shadow-lg hover:bg-blue-800 hover:bg-opacity-50 px-4 sm:px-8 py-6 sm:py-12">
						<div className="w-1/3 xl:w-1/4">
							<img src={six} alt="Six" className="w-16 block sm:mx-auto" />
						</div>
						<div className="w-2/3 xl:w-3/4">
							<h3 className="tracking-wide text-blue-300 font-bold text-2xl uppercase mb-2">User-Centric Design</h3>
							<div className="font-light text-gray-200 text-lg">
								<p>See firsthand how our development process integrates user-centric design principles. We focus not just on functionality but on creating delightful user experiences, ensuring that our solutions are intuitive, engaging, and aligned with user expectations.</p>
							</div>
						</div>
					</div>
				</div>
				<div className="self-center text-center w-full px-10 sm:px:0">
					<h3 className="font-bold tracking-widest text-blue-200 text-2xl my-6">The Technology Showcase at DevStackUp is more than a display—it's a testament to our commitment to staying ahead in the ever-evolving tech landscape. Join us on a tour of innovation, where each technology showcased represents a strategic choice in crafting solutions that exceed expectations.</h3>
				</div>
				<div className="flex flex-row w-full justify-center pt-6">
					<a className="px-10 py-2 text-gray-200 bg-blue-600 rounded-full shadow-md text-lg hover:bg-gray-800 hover:border-red" href="/contact-us">
						Register Now
					</a>
				</div>
			</div>
		);
	}
}
