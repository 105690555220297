import React from "react";
import AppLayout from "../layouts/AppLayout";
import HomeSlider from "../components/HomeSlider.component";
import Contact from "../components/Contact.component";
import Testimonial from "../components/Testimonial.component";
import HomeSection from "../components/HomeSection.component";
import HomeTechShowCase from "../components/HomeTechShowCase.component";
import HomePageTechs from "../components/HomePageTechs";

export default class Home extends React.Component {
	pagination = {
		clickable: true,
		dynamicBullets: true,
		renderBullet: function (index: number, className: string) {
			return '<span class="' + className + '">' + (index + 1) + "</span>";
		},
	};

	render() {
		return (
			<AppLayout>
				<HomeSlider />
				<HomeSection />
				<HomeTechShowCase />
				<Testimonial />
				<HomePageTechs />
				<Contact />
			</AppLayout>
		);
	}
}
