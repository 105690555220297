import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";
import m1 from "../assets/avatars/male1.png";
import m2 from "../assets/avatars/male2.png";
import m3 from "../assets/avatars/male3.png";
import m4 from "../assets/avatars/male4.png";
import m5 from "../assets/avatars/male5.png";
import m6 from "../assets/avatars/male6.png";
import m7 from "../assets/avatars/male7.png";
import m8 from "../assets/avatars/male8.png";
import m9 from "../assets/avatars/male9.png";
import m10 from "../assets/avatars/male10.png";
import m11 from "../assets/avatars/male11.png";
import m12 from "../assets/avatars/male12.png";

import f1 from "../assets/avatars/female1.png";
import f2 from "../assets/avatars/female2.png";
import f3 from "../assets/avatars/female3.png";
import f4 from "../assets/avatars/female4.png";
import f5 from "../assets/avatars/female5.png";
import f6 from "../assets/avatars/female6.png";
import f7 from "../assets/avatars/female7.png";
import f8 from "../assets/avatars/female8.png";
import f9 from "../assets/avatars/female9.png";
import f10 from "../assets/avatars/female10.png";
import f11 from "../assets/avatars/female11.png";
import f12 from "../assets/avatars/female12.png";
import f13 from "../assets/avatars/female13.png";
//import f14 from "../assets/female14.png";
//import f15 from "../assets/female15.png";

export default class Testimonial extends React.Component {
	testimonials = [
		{
			img: m1,
			name: "Pradeep Kumar",
			text: "DevStackUp transforms novices into coding maestros. Engage, learn, and thrive in an environment that cultivates skill, creativity, and innovation.",
		},
		{
			img: f1,
			name: "Divya Chandran",
			text: "Explore coding frontiers with DevStackUp's diverse learning modules. Master languages, frameworks, and best practices to accelerate your coding prowess.",
		},
		{
			img: m2,
			name: "Sebastian Philip",
			text: "Join DevStackUp for hands-on coding experiences. Develop real-world projects, gaining practical insights to enhance your skill set and boost employability.",
		},
		{
			img: f2,
			name: "Maya Suresh",
			text: "DevStackUp's supportive community fosters collaboration and knowledge sharing. Network with like-minded coders, mentors, and industry professionals to accelerate your learning journey.",
		},
		{
			img: m3,
			name: "Rahul Nambiar",
			text: "Immerse yourself in DevStackUp's interactive challenges. Hone problem-solving skills and enhance your coding finesse through engaging, real-world scenarios.",
		},
		{
			img: f3,
			name: "Maria Joseph",
			text: "At DevStackUp, personalized learning paths cater to your coding aspirations. Tailor your journey with expert-guided courses and unlock your full potential.",
		},
		{
			img: f4,
			name: "Annamma Thomas",
			text: "Stay updated with the latest tech trends. DevStackUp keeps you ahead with cutting-edge content, ensuring your skills remain relevant in the ever-evolving tech landscape.",
		},
		{
			img: f5,
			name: "Ayesha Rahman",
			text: "Participate in hackathons and coding competitions organized by DevStackUp. Challenge yourself, showcase your talent, and earn recognition within the coding community.",
		},
		{
			img: m4,
			name: "Harish Kumar",
			text: "Accelerate your career with DevStackUp's job readiness programs. Gain valuable industry insights, polish your resume, and confidently enter the competitive tech job market.",
		},
		{
			img: m5,
			name: "Rajesh Menon",
			text: "DevStackUp's mentorship program connects you with industry professionals. Receive personalized guidance, insights, and advice to fast-track your coding career.",
		},
		{
			img: m6,
			name: "Vivek Nair",
			text: "Unlock exclusive resources at DevStackUp. Access coding libraries, templates, and tools curated to empower your projects and amplify your coding efficiency.",
		},
		{
			img: m7,
			name: "Farhan Ali",
			text: "Enhance your coding portfolio with DevStackUp's project showcase. Feature your work, receive feedback, and build a compelling profile to impress potential employers.",
		},
		{
			img: f6,
			name: "Ananya Sreekumar",
			text: "DevStackUp's weekly coding challenges keep your skills sharp. Regular practice ensures you stay at the forefront of technological advancements in the coding world.",
		},
		{
			img: f7,
			name: "Devika Pillai",
			text: "Dive into DevStackUp's interactive workshops. From beginner basics to advanced techniques, our workshops provide hands-on learning to enrich your coding journey.",
		},
		{
			img: m8,
			name: "Joseph Mathew",
			text: "Connect with industry leaders through DevStackUp's networking events. Forge valuable relationships, gain insights, and open doors to exciting opportunities in the coding realm.",
		},
		{
			img: m9,
			name: "Arjun Menon",
			text: "DevStackUp's commitment to diversity and inclusion ensures a welcoming environment for all. Join a global community that celebrates uniqueness and promotes equal opportunities.",
		},
		{
			img: f8,
			name: "Meera Krishnan",
			text: "DevStackUp's gamified learning approach makes coding fun. Level up your skills, earn badges, and enjoy a dynamic learning experience tailored to your individual progress.",
		},
		{
			img: m10,
			name: "Idris Abdul",
			text: "Empower your coding journey with DevStackUp's continuous feedback loop. Receive constructive critiques, refine your skills, and evolve into a coding virtuoso.",
		},
		{
			img: f9,
			name: "Teresa George",
			text: "Join DevStackUp's code review sessions. Learn from peer feedback, improve your coding practices, and cultivate a collaborative mindset essential for professional growth.",
		},
		{
			img: f10,
			name: "Kavya Rajan",
			text: "DevStackUp's commitment to lifelong learning ensures continuous growth. Stay engaged with evolving technologies, and let your coding skills flourish throughout your career.",
		},
		{
			img: m11,
			name: "Xavier Antony",
			text: "Maximize productivity with DevStackUp's coding shortcuts and tips. Discover time-saving techniques that streamline your workflow and boost your coding efficiency.",
		},
		{
			img: f11,
			name: "Aishwarya Pillai",
			text: "DevStackUp's commitment to innovation extends to AI and emerging technologies. Explore futuristic coding concepts, ensuring you remain at the forefront of technological advancements.",
		},
		{
			img: m12,
			name: "Yusuf Ahmed",
			text: "DevStackUp's global coding community is a resource-rich ecosystem. Leverage the collective wisdom, share your experiences, and grow with fellow coding enthusiasts worldwide.",
		},
		{
			img: f12,
			name: "Lakshmi Rajendran",
			text: "Craft compelling code with DevStackUp's code optimization workshops. Learn to write efficient, scalable code that sets you apart in the competitive coding landscape.",
		},
		{
			img: f13,
			name: "Zara Khan",
			text: "DevStackUp empowers you to lead in tech. From coding basics to leadership skills, our comprehensive curriculum equips you for success in any coding career path.",
		},
	];
	render() {
		return (
			<div className="w-full bg-cprimary-100 py-10 px-auto md:px-6 text-center">
				<h2 className="text-3xl font-bold mb-7">Testimonials</h2>
				<Swiper
					slidesPerView={1}
					spaceBetween={30}
					autoplay={{
						delay: 2000,
						disableOnInteraction: false,
					}}
					breakpoints={{
						1024: {
							slidesPerView: 3,
							spaceBetween: 50,
						},
					}}
					modules={[Autoplay]}
					className="testimonialSwiper"
				>
					{this.testimonials.map((t) => {
						return (
							<SwiperSlide>
								<div className="py-5 h-[450px] px-2">
									<div className="mb-6 flex justify-center w-full">
										<img alt="testimonial" src={t.img} className="w-32 h-32 rounded-full shadow-lg mx-auto" />
									</div>
									<h5 className="mb-2 text-lg font-bold">{t.name}</h5>
									<h6 className="hidden mb-4 font-medium text-primary dark:text-primary-400">Web Developer</h6>
									<p className="mb-4">
										{t.text}
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" className="inline-block w-6">
											<path fill="currentColor" d="M580 556h160V396H580v160Zm-360 0h160V396H220v160Zm406 220 80-160H520V336h280v288l-76 152h-98Zm-360 0 80-160H160V336h280v288l-76 152h-98Zm34-300Zm360 0Z" />
										</svg>
									</p>
									<ul className="mb-0 flex justify-center">
										<li>
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" className="w-5 text-warning">
												<path fill="currentColor" d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
											</svg>
										</li>
										<li>
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" className="w-5 text-warning">
												<path fill="currentColor" d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
											</svg>
										</li>
										<li>
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" className="w-5 text-warning">
												<path fill="currentColor" d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
											</svg>
										</li>
										<li>
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" className="w-5 text-warning">
												<path fill="currentColor" d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
											</svg>
										</li>
										<li>
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" className="w-5 text-warning">
												<path fill="currentColor" d="m480 757 157 95-42-178 138-120-182-16-71-168v387ZM233 976l65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
											</svg>
										</li>
									</ul>
								</div>
							</SwiperSlide>
						);
					})}
				</Swiper>
			</div>
		);
	}
}
