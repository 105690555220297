import React from "react";

export default class Contact extends React.Component {
	render() {
		return (
			<div className="w-full">
				<section className="bg-white">
					<div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
						<h2 className="text-3xl font-bold text-center mb-3">Contact</h2>
						<p className="font-light text-center text-gray-500 sm:text-xl  mb-3">Dont hesitate!</p>
						<p className="mb-4 font-light text-center text-gray-500 sm:text-xl">Embark on your technology journey!</p>
					</div>
				</section>
				<div className="flex m-auto mb-10">
					<a href="/contact-us" className="m-auto">
						<button className="m-auto bg-cprimary-800 hover:bg-cprimary-500 text-2xl text-white font-semibold py-2 px-4 border border-white hover:border-transparent rounded">Join US</button>
					</a>
				</div>
			</div>
		);
	}
}
