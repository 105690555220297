import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-cube";
import { EffectCube, Autoplay, Pagination, Navigation } from "swiper/modules";
import mern from "../assets/homeslider/mern_stack.png";
import mean from "../assets/homeslider/mean_stack.png";

import { Navigate } from "react-router-dom";
import bg1 from "../assets/homeslider/bg1.jpg";
import bg2 from "../assets/homeslider/bg2.jpg";
import bg3 from "../assets/homeslider/bg3.jpg";
import bg4 from "../assets/homeslider/bg4.jpg";

export default class HomeSlider extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			redirect: "",
		};
	}
	pagination = {
		clickable: true,
		dynamicBullets: true,
		renderBullet: function (index: number, className: string) {
			return '<span class="' + className + '">' + (index + 1) + "</span>";
		},
	};

	render() {
		return (
			<div className="w-full">
				{this.state.redirect !== "" && <Navigate to={this.state.redirect} />}
				<Swiper
					pagination={this.pagination}
					effect={"cube"}
					spaceBetween={30}
					centeredSlides={true}
					grabCursor={true}
					autoplay={{
						delay: 4000,
						disableOnInteraction: false,
					}}
					autoHeight={false}
					navigation={false}
					modules={[EffectCube, Autoplay, Pagination, Navigation]}
					className="mySwiper"
					cubeEffect={{
						shadow: true,
						slideShadows: true,
						shadowOffset: 10,
						shadowScale: 0.5,
					}}
				>
					{/* DevStackUp */}
					<SwiperSlide>
						<div className="w-full h-96 flex bg-cover bg-center bg-no-repeat" style={{ backgroundImage: `url(${bg1})` }}>
							<div className="m-auto w-full">
								<div className="text-white font-bold text-4xl mb-5">DevStackUp</div>
								<div className="text-white uppercase font-semibold text-2xl">Join Us and Become a Professional Developer</div>
								<div className="mt-5">
									<a href="/contact-us">
										<button className="bg-transparent hover:bg-cprimary-500 text-white font-semibold py-2 px-4 border border-white hover:border-transparent rounded">Join DevStackUp</button>
									</a>
								</div>
							</div>
						</div>
					</SwiperSlide>
					{/* Process */}
					<SwiperSlide>
						<div className="w-full h-96 flex bg-cover bg-center bg-no-repeat" style={{ backgroundImage: `url(${bg2})` }}>
							<div className="m-auto space-y-5 w-full">
								<div className="text-white font-bold text-4xl">Our Process</div>
								<div className="space-y-2">
									<div className="text-white flex w-full">
										<div className="m-auto space-x-2">
											<span className="text-[#ebc999] font-semibold text-xl">Identify</span>
											<span className="italic"> your technical capabilities</span>
										</div>
									</div>
									<div className="text-white flex w-full">
										<div className="m-auto space-x-2">
											<span className="text-[#e9bbba] font-semibold text-xl">Enhance</span>
											<span className="italic"> with self learning guidance</span>
										</div>
									</div>
									<div className="text-white flex w-full">
										<div className="m-auto space-x-2">
											<span className="text-[#ebebde] font-semibold text-xl">Master</span>
											<span className="italic"> in world of coding</span>
										</div>
									</div>
								</div>
								<div className="mt-5">
									<button onClick={() => this.setState({ redirect: "/our-process" })} className="bg-transparent hover:bg-cprimary-500 text-white font-semibold py-2 px-4 border border-white hover:border-transparent rounded">
										Learn More
									</button>
								</div>
							</div>
						</div>
					</SwiperSlide>
					{/* FULL STACK */}
					<SwiperSlide>
						<div className="bg-cprimary-900 w-full h-96 flex bg-cover bg-center bg-no-repeat" style={{ backgroundImage: `url(${bg3})` }}>
							<div className="m-auto space-y-5 bg-gray-600 rounded-lg p-10 bg-opacity-75">
								<div className="text-white font-bold text-4xl">Full Stack</div>
								<div className="space-y-2">
									<div className="text-white flex w-full">
										<div className="m-auto space-x-2">
											<span className="text-[#ebc999] font-semibold text-xl">Front End</span>
										</div>
									</div>
									<div className="text-white flex w-full">
										<div className="m-auto space-x-2">
											<span className="text-[#e9bbba] font-semibold text-xl">Backend</span>
										</div>
									</div>
									<div className="text-white flex w-full">
										<div className="m-auto space-x-2">
											<span className="text-[#ebebde] font-semibold text-xl">Database</span>
										</div>
									</div>
								</div>
								<div className="mt-5">
									<a href="/contact-us">
										<button className="bg-gray-400 hover:bg-cprimary-500 text-white font-semibold py-2 px-4 border border-white hover:border-transparent rounded">Learn More</button>
									</a>
								</div>
							</div>
						</div>
					</SwiperSlide>
					{/* MERN */}
					<SwiperSlide>
						<div className="bg-mern w-full h-96 flex bg-cover bg-center" style={{ backgroundImage: `url(${mern})` }}>
							<div className="mx-auto mt-auto mb-20 lg:mb-10">
								<a href="/contact-us">
									<button className="bg-cprimary-900 hover:bg-cprimary-500 text-white font-semibold py-2 px-4 border border-white hover:border-transparent rounded">Learn More</button>
								</a>
							</div>
						</div>
					</SwiperSlide>
					{/* MEAN */}
					<SwiperSlide>
						<div className="bg-mern w-full h-96 flex bg-cover bg-center" style={{ backgroundImage: `url(${mean})` }}>
							<div className="mx-auto mt-auto mb-20 lg:mb-10">
								<a href="/contact-us">
									<button className="bg-cprimary-900 hover:bg-cprimary-500 text-white font-semibold py-2 px-4 border border-white hover:border-transparent rounded">Learn More</button>
								</a>
							</div>
						</div>
					</SwiperSlide>
					{/* Python */}
					<SwiperSlide>
						<div className="bg-cprimary-900 w-full h-96 flex">
							<div className="m-auto space-y-5 w-full">
								<div className="text-white font-bold text-4xl">Python Django</div>
								<div className="space-y-2">
									<div className="text-white flex w-full">
										<div className="m-auto space-x-2">
											<span className="text-[#ebc999] font-semibold text-xl">Python</span>
										</div>
									</div>
									<div className="text-white flex w-full">
										<div className="m-auto space-x-2">
											<span className="text-[#e9bbba] font-semibold text-xl">Django</span>
										</div>
									</div>
									<div className="text-white flex w-full">
										<div className="m-auto space-x-2">
											<span className="text-[#ebebde] font-semibold text-xl">Angular/React</span>
										</div>
									</div>
									<div className="text-white flex w-full">
										<div className="m-auto space-x-2">
											<span className="text-[#ebebde] font-semibold text-xl">Git</span>
										</div>
									</div>
								</div>
								<div className="mt-5">
									<a href="/contact-us">
										<button className="bg-transparent hover:bg-cprimary-500 text-white font-semibold py-2 px-4 border border-white hover:border-transparent rounded">Join DevStackUp</button>
									</a>
								</div>
							</div>
						</div>
					</SwiperSlide>
					<SwiperSlide>
						<div className="w-full h-96 flex bg-cover bg-center bg-no-repeat" style={{ backgroundImage: `url(${bg4})` }}>
							<div className="m-auto space-y-5 w-full">
								<div className="">
									<a href="/contact-us">
										<button className="bg-cprimary-800 hover:bg-cprimary-500 text-white font-semibold py-2 px-4 border border-white hover:border-transparent rounded">Join US</button>
									</a>
								</div>
							</div>
						</div>
					</SwiperSlide>
				</Swiper>
			</div>
		);
	}
}
