import React from "react";
import AppLayout from "../layouts/AppLayout";
import contact from "../assets/contact-us.jpg";

export default class ContactUs extends React.Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			name: "",
			phone: "",
			message: "",
			isSubmitted: false,
			success: false,
		};
	}

	render() {
		return (
			<AppLayout>
				<div className="max-w-screen-xl mx-auto px-5 mb-10">
					<div className="mt-8 lg:mt-16 text-center">
						<div style={{ backgroundImage: `url(${contact})` }} className="hidden lg:block rounded-md bg-cover bg-center h-[300px] lg:h-[300px] bg-no-repeat"></div>
						<h1 className="block lg:hidden text-3xl font-extrabold leading-tight text-gray-900 lg:mb-0 lg:text-4xl">Contact DevStackUp</h1>
					</div>
					<div className="grid md:grid-cols-2 gap-10 mx-auto max-w-4xl lg:mt-10 sm:mt-0">
						<div>
							<p className="text-lg leading-relaxed text-slate-500 mt-3">Have something to say? We are here to help. Fill up the form or send email or call phone.</p>
							<div className="mt-5">
								<div className="flex items-center mt-2 space-x-2 text-gray-600">
									<svg viewBox="0 0 24 24" className="text-gray-400 w-4 h-4" astro-icon="uil:map-marker">
										<path fill="currentColor" d="M12 2a8 8 0 0 0-8 8c0 5.4 7.05 11.5 7.35 11.76a1 1 0 0 0 1.3 0C13 21.5 20 15.4 20 10a8 8 0 0 0-8-8zm0 17.65c-2.13-2-6-6.31-6-9.65a6 6 0 0 1 12 0c0 3.34-3.87 7.66-6 9.65zM12 6a4 4 0 1 0 4 4 4 4 0 0 0-4-4zm0 6a2 2 0 1 1 2-2 2 2 0 0 1-2 2z"></path>
									</svg>
									<span>1st Floor, KC Center, Karunagapally, Kerala - 690518</span>
								</div>
								<div className="flex items-center mt-2 space-x-2 text-gray-600">
									<svg viewBox="0 0 24 24" className="text-gray-400 w-4 h-4" astro-icon="uil:envelope">
										<path fill="currentColor" d="M19 4H5a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3zm-.41 2-5.88 5.88a1 1 0 0 1-1.42 0L5.41 6zM20 17a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V7.41l5.88 5.88a3 3 0 0 0 4.24 0L20 7.41z"></path>
									</svg>
									<a href="mailto:info@devstackup.in">info@devstackup.in</a>
								</div>
								<div className="flex items-center mt-2 space-x-2 text-gray-600">
									<svg viewBox="0 0 24 24" className="text-gray-400 w-4 h-4" astro-icon="uil:phone">
										<path fill="currentColor" d="M19.44 13c-.22 0-.45-.07-.67-.12a9.44 9.44 0 0 1-1.31-.39 2 2 0 0 0-2.48 1l-.22.45a12.18 12.18 0 0 1-2.66-2 12.18 12.18 0 0 1-2-2.66l.42-.28a2 2 0 0 0 1-2.48 10.33 10.33 0 0 1-.39-1.31c-.05-.22-.09-.45-.12-.68a3 3 0 0 0-3-2.49h-3a3 3 0 0 0-3 3.41 19 19 0 0 0 16.52 16.46h.38a3 3 0 0 0 2-.76 3 3 0 0 0 1-2.25v-3a3 3 0 0 0-2.47-2.9zm.5 6a1 1 0 0 1-.34.75 1.05 1.05 0 0 1-.82.25A17 17 0 0 1 4.07 5.22a1.09 1.09 0 0 1 .25-.82 1 1 0 0 1 .75-.34h3a1 1 0 0 1 1 .79q.06.41.15.81a11.12 11.12 0 0 0 .46 1.55l-1.4.65a1 1 0 0 0-.49 1.33 14.49 14.49 0 0 0 7 7 1 1 0 0 0 .76 0 1 1 0 0 0 .57-.52l.62-1.4a13.69 13.69 0 0 0 1.58.46q.4.09.81.15a1 1 0 0 1 .79 1z"></path>
									</svg>
									<a href="tel:+1 (987) 4587 899">+91 98474 03039</a>
								</div>
							</div>
						</div>
						<div>
							<div>
								<div className="mb-5">
									<input
										onChange={(e) =>
											this.setState({
												name: e.target.value,
											})
										}
										disabled={this.state.success}
										type="text"
										placeholder="Full Name"
										className="w-full px-4 py-3 border-2 placeholder:text-gray-800 rounded-md outline-none focus:ring-4 border-gray-300 focus:border-gray-600 ring-gray-100"
										name="name"
									/>
									{this.state.isSubmitted && this.state.name == "" && <div className="text-red-400 text-sm mt-1">Please provide your full name.</div>}
								</div>
								<div className="mb-5">
									<label htmlFor="phone_number" className="sr-only">
										Phone Number
									</label>
									<input
										onChange={(e) =>
											this.setState({
												phone: e.target.value,
											})
										}
										disabled={this.state.success}
										id="phone_number"
										type="email"
										placeholder="Phone Number"
										name="phone_number"
										className="w-full px-4 py-3 border-2 placeholder:text-gray-800 rounded-md outline-none focus:ring-4 border-gray-300 focus:border-gray-600 ring-gray-100"
									/>
									{this.state.isSubmitted && this.state.phone === "" && <div className="text-red-400 text-sm mt-1">Please provide contact phone.</div>}
								</div>
								<div className="mb-3">
									<textarea disabled={this.state.success} name="message" placeholder="Your Message" className="w-full px-4 py-3 border-2 placeholder:text-gray-800 rounded-md outline-none h-36 focus:ring-4 border-gray-300 focus:border-gray-600 ring-gray-100"></textarea>
								</div>
								<button
									onClick={async () => {
										this.setState({
											isSubmitted: true,
										});
										if (this.state.name !== "" && this.state.phone !== "") {
											const data = {
												name: this.state.name,
												phone: this.state.phone,
												message: this.state.message,
											};
											const FORM_ENDPOINT = "https://public.herotofu.com/v1/76bd8720-f9d7-11ec-bc36-e1ea9ccadd33";
											await fetch(FORM_ENDPOINT, {
												method: "POST",
												headers: {
													Accept: "application/json",
													"Content-Type": "application/json",
												},
												body: JSON.stringify(data),
											});
											this.setState({
												success: true,
											});
										}
									}}
									disabled={this.state.success}
									type="submit"
									className={"rounded text-center transition focus-visible:ring-2 ring-offset-2 ring-gray-200 w-full px-6 py-3 text-white border-2 border-transparent" + (this.state.success ? " bg-gray-400" : " bg-blue-600 hover:bg-slate-900")}
								>
									Send Message
								</button>
								{this.state.success && (
									<div id="" className="mt-3 text-center">
										Your request is submitted successfully!
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</AppLayout>
		);
	}
}
