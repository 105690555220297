import { Component } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import Home from "./pages/Home";
import OurProcess from "./pages/OurProcess";

type Props = {};

type State = {};

export default class App extends Component<Props, State> {
	componentDidMount() {}

	render() {
		return (
			<>
				<Routes>
					<Route path="/home" element={<Home />} />
					<Route path="/our-process" element={<OurProcess />} />
					<Route path="/about-us" element={<AboutUs />} />
					<Route path="/contact-us" element={<ContactUs />} />
					<Route path="/401" element={<div>Not Found</div>} />

					<Route path="*" element={<Navigate to="/home" />} />
				</Routes>
			</>
		);
	}
}
