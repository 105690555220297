import React from "react";
import { FaPhoneAlt, FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";
import { AiFillTwitterCircle } from "react-icons/ai";

import { IoMail } from "react-icons/io5";

export default class Footer extends React.Component {
	render() {
		return (
			<footer className="bg-cprimary-900 text-white p-5 text-base lg:flex">
				<div className="w-full">
					<div className="w-full justify-center flex font-medium mb-3 text-lg">Address</div>
					<div className="flex justify-center font-light">1st Floor, KC Center, Karunagapally</div>
					<div className="flex justify-center font-light">Kollam, Kerala - 690518</div>
					<div className="flex mt-1">
						<iframe className="m-auto hidden" title="googlemap" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3941.611910801196!2d76.63023117558559!3d8.915623291141221!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b05fd98c4df2101%3A0x75f95e997b54134!2sDevStackUp!5e0!3m2!1sen!2sin!4v1702039531955!5m2!1sen!2sin" width="200" height="200" style={{ border: 0 }} allowFullScreen={false} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
					</div>
				</div>
				<div className="w-full mt-5 lg:mt-0">
					<div className="w-full justify-center flex font-medium mb-3 text-lg">Contact</div>
					<div className="flex justify-center font-light h-7">
						<FaPhoneAlt className="mt-2" />
						<span className="mt-auto ml-1">+91 98474 03039</span>
					</div>
					<div className="flex justify-center font-light h-7">
						<FaPhoneAlt className="mt-2" />
						<span className="mt-auto ml-1">+91 99474 90833</span>
					</div>
					<div className="flex justify-center font-light h-7">
						<IoMail className="mt-2" />
						<span className="mt-auto ml-1">info@devstackup.in</span>
					</div>
				</div>
				<div className="w-full mt-5 lg:mt-0">
					<div className="w-full justify-center flex font-medium mb-3 text-lg">Social</div>
					<div className="flex justify-center space-x-3">
						<FaFacebook className="w-7 h-7 my-auto" />
						<AiFillTwitterCircle className="w-7 h-7 my-auto" />
						<FaInstagram className="w-7 h-7" />
						<FaLinkedin className="w-7 h-7" />
					</div>
				</div>
			</footer>
		);
	}
}
