import React from "react";
import AppLayout from "../layouts/AppLayout";
import about from "../assets/about-us.jpg";

export default class AboutUs extends React.Component {
	render() {
		return (
			<AppLayout>
				<div className="w-full mx-auto px-5 mb-16 mt-6">
					<div className="mx-auto max-w-7xl w-full format-sm sm:format-base lg:format-lg format-blue">
						<header className="mb-4 lg:mb-6 not-format">
							{/* <h1 className="mb-4 text-3xl font-extrabold leading-tight text-gray-900 lg:mb-6 lg:text-4xl">About Us</h1> */}
							<img src={about} alt="About Us" />
						</header>
						<p className="lead">Welcome to DevStackUp, where innovation meets expertise! With over 15 years of dedicated experience in crafting cutting-edge software solutions, we stand as a beacon of excellence in the dynamic world of technology.</p>
						<h2>Our Journey</h2>
						<p>Since our inception, we have been at the forefront of software development, delivering tailored solutions that transcend industry standards. Our journey is marked by a relentless commitment to innovation, quality, and client satisfaction.</p>
						<h2>Expertise That Matters</h2>
						<p>DevStackUp brings a wealth of expertise to the table. From small startups to large enterprises, we have collaborated with clients of various sizes, each with unique challenges and aspirations. Our seasoned team of developers, engineers, and architects leverages this rich experience to create solutions that not only meet but exceed expectations.</p>
						<h2>Building Tomorrow's Solutions Today</h2>
						<p>In the fast-paced world of technology, staying ahead is not just a choice—it's a necessity. DevStackUp embraces this philosophy, consistently adopting emerging technologies and industry best practices. Our forward-thinking approach ensures that the solutions we build today are equipped to meet the challenges of tomorrow.</p>
						<h2>Training the Future</h2>
						<p>Beyond software development, we are passionate about nurturing the next generation of tech talent. DevStackUp offers comprehensive training programs designed to empower individuals on their coding journey. Whether you're a beginner or an experienced professional, our training initiatives are crafted to elevate your skills and enhance your mastery.</p>
						<h2>Core Values</h2>
						<p>At the heart of DevStackUp are our core values:</p>
						<ul className="my-5 leading-8">
							<li>
								<strong>Excellence: </strong>We strive for excellence in everything we do, from coding to client interactions.
							</li>
							<li>
								<strong>Innovation: </strong>Embracing the spirit of innovation, we constantly seek new and better ways to solve problems.
							</li>
							<li>
								<strong>Integrity: </strong>Our commitment to integrity ensures honesty, transparency, and trust in every collaboration.
							</li>
							<li>
								<strong>Community: </strong>We believe in the power of community and actively contribute to the growth of the coding ecosystem.
							</li>
						</ul>
						<h2>Join the DevStackUp Experience</h2>
						<p>Whether you're a client looking for a trusted technology partner or an individual seeking to enhance your coding skills, DevStackUp is your destination. Join us on this exciting journey where expertise meets innovation, and together, let's build and learn for a brighter future.</p>
						<h3>
							Welcome to DevStackUp — <span className="italic">Where Your Vision Takes Flight</span>.
						</h3>
					</div>
				</div>
			</AppLayout>
		);
	}
}
