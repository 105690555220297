import React from "react";
import AppLayout from "../layouts/AppLayout";
import ourprocess from "../assets/our-process.png";
import Contact from "../components/Contact.component";

export default class OurProcess extends React.Component {
	render() {
		return (
			<AppLayout>
				<main className="pt-8 pb-16 lg:pb-24 bg-white antialiased">
					<div className="flex justify-between px-4 mx-auto max-w-screen-6xl ">
						<article className="mx-auto w-full max-w-6xl format-sm sm:format-base lg:format-lg format-blue">
							<header className="mb-6 lg:mb-10 not-format">
								<img src={ourprocess} alt="Coding mastery program" className="rounded-md" />
							</header>
							<p className="lead">At DevStackUp, we believe in a transformative journey that turns aspiring coders into true masters. Our unique process encompasses three key phases:</p>
							<ul className="my-5 leading-8 hidden">
								<li>
									<strong>Identify Candidate's Technical Capabilities</strong>.
								</li>
								<li>
									<strong>Enhance Skills with Self-Learning Guidance</strong>.
								</li>
								<li>
									<strong>Master; Transform into a Coding Expert</strong>.
								</li>
							</ul>
							<h2>1. Identify Candidate's Technical Capabilities</h2>
							<h3>Holistic Skills Assessment</h3>
							<p>Our program begins with a holistic evaluation of your technical skills. Through a series of carefully crafted assessments, we delve deep into your proficiency across various coding languages, problem-solving methodologies, and fundamental programming concepts. This comprehensive approach ensures that every facet of your technical capabilities is thoroughly examined.</p>
							<h3>360-Degree Skill Mapping</h3>
							<p>We go beyond a simple skills assessment by employing a 360-degree skill mapping strategy. This involves identifying not only your strengths but also areas that present opportunities for growth. By mapping your skills comprehensively, we create a nuanced and detailed profile that serves as the foundation for your personalized learning journey.</p>
							<h3>Customized Skill Profiles</h3>
							<p>Every candidate is unique, and so is their skill set. Our process involves creating customized skill profiles for each individual, providing a detailed breakdown of strengths, weaknesses, and potential areas for improvement. This personalized approach ensures that the subsequent phases of the program are precisely tailored to meet your specific needs and goals.</p>
							<h3>Data-Driven Insights</h3>
							<p>Leveraging data-driven insights from the assessments, we gain a clear understanding of your current standing in the coding landscape. This information becomes the basis for crafting a roadmap that guides you from your current skill level to the pinnacle of coding mastery. These insights not only inform the learning path but also help set realistic and achievable milestones.</p>
							<blockquote>Through this meticulous identification process, we lay the groundwork for a transformative learning experience that recognizes and respects the uniqueness of each candidate. From here, we move seamlessly into the next phases of the program, ready to enhance and ultimately master your coding skills.</blockquote>

							<h2>2. Enhance Skills with Self-Learning Guidance</h2>
							<h3>Tailored Learning Paths</h3>
							<p>Building on the insights gained from the skills assessment, embark on a journey of personalized learning. Tailored learning paths are meticulously designed to address specific strengths and areas for improvement. These paths serve as a roadmap, guiding through a curated curriculum aligned with individual learning objectives.</p>
							<h3>Diverse Learning Resources</h3>
							<p>Access a rich array of learning resources catering to various learning styles. From comprehensive tutorials and interactive coding challenges to engaging real-world projects, the program provides a diverse set of materials. This allows learners to engage with content in a way that resonates with them, fostering a deeper understanding of coding concepts.</p>
							<h3>Self-Paced Mastery</h3>
							<p>Take control of the learning journey with self-paced mastery. The platform empowers learners to set their own pace, ensuring a thorough grasp of each concept before moving forward. This flexibility accommodates diverse schedules and learning preferences, making the learning experience both effective and enjoyable.</p>
							<h3>Guided Mentorship</h3>
							<p>Complementing self-learning, the program offers opportunities for guided mentorship. Connect with experienced mentors who provide valuable insights, answer queries, and offer guidance on navigating challenges. This mentorship component adds a human touch to the learning process, creating a supportive environment for skill development.</p>
							<h3>Continuous Evaluation</h3>
							<p>Throughout the self-learning journey, continuous evaluation mechanisms are in place to track progress. Regular assessments and checkpoints ensure learners are consistently moving toward their goals. This iterative feedback loop is designed to reinforce strengths and guide focused efforts on areas that may require additional attention.</p>
							<blockquote>Through this dynamic and self-guided learning phase, you not only enhance your coding skills but also cultivate a sense of autonomy in your educational journey. The combination of personalized paths, diverse resources, guided mentorship, and continuous evaluation sets the stage for a robust and effective skill enhancement process.</blockquote>

							<h2>3. Master: Candidate Becomes a Coding Master</h2>
							<h3>Progress Tracking</h3>
							<p>Implement a robust tracking system to monitor progress through regular assessments and checkpoints. This ensures continuous improvement as candidates engage with the learning resources. Milestones are set to celebrate achievements and provide motivation for the journey ahead.</p>
							<h3>Project-Based Learning:</h3>
							<p>Emphasize practical, project-based learning to bridge the gap between theory and real-world application. Candidates tackle complex coding projects that showcase their mastery, applying knowledge gained throughout the program to create meaningful, tangible results.</p>
							<h3>Peer Collaboration</h3>
							<p>Foster a collaborative environment where candidates can share experiences, learn from peers, and participate in group projects. Collaboration enhances problem-solving skills and encourages a community of continuous learners who support each other on the path to mastery.</p>
							<h3>Certification and Recognition</h3>
							<p>Recognize candidates' achievements with certifications and acknowledgments upon reaching key milestones. Formalize their status as coding masters, reinforcing their expertise and providing tangible proof of their capabilities to potential employers and the coding community.</p>
							<h3>Lifelong Learning Commitment</h3>
							<p>Empower candidates to lead in the ever-evolving tech landscape. The program's commitment to lifelong learning ensures continuous growth. Stay engaged with emerging technologies, industry trends, and innovative coding concepts, allowing mastery to extend beyond the program and throughout their coding careers.</p>
							<blockquote>Through this multifaceted journey that involves technical prowess, problem-solving acumen, continuous learning, effective communication, commitment to quality, and a spirit of mentorship. It is a dynamic and ongoing process that distinguishes those who have not only learned to code but have truly mastered the art and science of programming.</blockquote>
						</article>
					</div>
					<Contact />
				</main>
			</AppLayout>
		);
	}
}
