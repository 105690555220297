import React from "react";
import logo from "../assets/dsu_logo.png";
import { Navigate } from "react-router-dom";

export default class Header extends React.Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			redirect: "",
		};
	}

	componentDidMount(): void {
		const burger = document.querySelectorAll(".navbar-burger");
		const menu = document.querySelectorAll(".navbar-menu");
		if (burger.length && menu.length) {
			for (var i = 0; i < burger.length; i++) {
				burger[i].addEventListener("click", function () {
					for (var j = 0; j < menu.length; j++) {
						menu[j].classList.toggle("hidden");
					}
				});
			}
		}
		// close
		const close = document.querySelectorAll(".navbar-close");
		const backdrop = document.querySelectorAll(".navbar-backdrop");
		if (close.length) {
			for (var i = 0; i < close.length; i++) {
				close[i].addEventListener("click", function () {
					for (var j = 0; j < menu.length; j++) {
						menu[j].classList.toggle("hidden");
					}
				});
			}
		}

		if (backdrop.length) {
			for (var i = 0; i < backdrop.length; i++) {
				backdrop[i].addEventListener("click", function () {
					for (var j = 0; j < menu.length; j++) {
						menu[j].classList.toggle("hidden");
					}
				});
			}
		}
	}

	getActiveClass(path: string) {
		if (window.location.href.indexOf(path) > -1) {
			return "bg-cprimary-600";
		}

		return "";
	}
	render() {
		return (
			<header className="h-20 text-white flex bg-cprimary-900">
				{this.state.redirect !== "" && <Navigate to={this.state.redirect} />}
				<nav className="relative px-4 py-4 flex justify-between items-center bg-cprimary-900 w-full">
					<a className="text-3xl font-bold leading-none" href="/home">
						<img src={logo} alt="DevStackUp" className="my-auto ml-3 cursor-pointer h-14" />
					</a>
					<div className="lg:hidden">
						<button className="navbar-burger flex items-center text-white p-3">
							<svg className="block h-7 w-7 fill-current" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
								<title>Mobile menu</title>
								<path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
							</svg>
						</button>
					</div>
					<ul className="hidden absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 lg:flex lg:mx-auto lg:items-center lg:w-auto lg:space-x-6">
						<li>
							<a className={"text-base text-white hover:bg-cprimary-500 rounded-md px-2 py-2 " + this.getActiveClass("/home")} href="/home">
								Home
							</a>
						</li>
						<li>
							<a className={"text-base text-white hover:bg-cprimary-500 rounded-md px-2 py-2 " + this.getActiveClass("/about-us")} href="/about-us">
								About Us
							</a>
						</li>
						<li>
							<a className={"text-base text-white hover:bg-cprimary-500 rounded-md px-2 py-2 " + this.getActiveClass("/our-process")} href="/our-process">
								Our Process
							</a>
						</li>
						<li>
							<a className={"text-base text-white hover:bg-cprimary-500 rounded-md px-2 py-2 " + this.getActiveClass("/contact-us")} href="/contact-us">
								Contact Us
							</a>
						</li>
						<li>
							<a className={"text-base text-white hover:bg-cprimary-500 rounded-md px-2 py-2 " + this.getActiveClass("/newdawnit")} href="https://newdawnit.in">
								Services
							</a>
						</li>
					</ul>
				</nav>
				<div className="navbar-menu relative z-50 hidden">
					<div className="navbar-backdrop fixed inset-0 bg-gray-800 opacity-25"></div>
					<nav className="fixed top-0 left-0 bottom-0 flex flex-col w-5/6 max-w-sm py-6 px-6 bg-cprimary-500 border-r overflow-y-auto">
						<div className="flex items-center mb-8">
							<a className="mr-auto text-3xl font-bold leading-none" href="/home">
								<img src={logo} alt="DevStackUp" className="my-auto ml-3 cursor-pointer h-14" />
							</a>
							<button className="navbar-close">
								<svg className="h-6 w-6 text-white cursor-pointer hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
									<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
								</svg>
							</button>
						</div>
						<div>
							<ul>
								<li className="mb-1">
									<a className="block p-4 text-sm font-semibold text-white hover:bg-blue-50 hover:text-blue-600 rounded" href="/home">
										Home
									</a>
								</li>
								<li className="mb-1">
									<a className="block p-4 text-sm font-semibold text-white hover:bg-blue-50 hover:text-blue-600 rounded" href="/about-us">
										About Us
									</a>
								</li>
								<li className="mb-1">
									<a className="block p-4 text-sm font-semibold text-white hover:bg-blue-50 hover:text-blue-600 rounded" href="/our-process">
										Our Process
									</a>
								</li>
								<li className="mb-1">
									<a className="block p-4 text-sm font-semibold text-white hover:bg-blue-50 hover:text-blue-600 rounded" href="/contact-us">
										Contact Us
									</a>
								</li>
								<li className="mb-1">
									<a className="block p-4 text-sm font-semibold text-white hover:bg-blue-50 hover:text-blue-600 rounded" href="https://newdawnit.in">
										Services
									</a>
								</li>
							</ul>
						</div>
					</nav>
				</div>
			</header>
		);
	}
}
